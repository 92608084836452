<template>
    <div class="table-container">
        <table class="table-groups group--list">
        <thead>
          <tr>
            <th v-for="(header,index) in headers" :key="index" :class="{'border-left': header.borderLeft}">
                <div class="header-cell">
                    <span>{{ toLocal(header.title) }}</span>
                   
                    <svg v-if="header.sortable" class="clickable-svg"  @click="setOrderBy(header.key)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path :class="{active: orderByElement == header.key && orderByDirection == 'desc'}" d="M6.60243 19.7168C6.28496 20.0774 5.75871 20.0955 5.42453 19.7529C5.41617 19.7439 5.39943 19.7258 5.39108 19.7168L0.228004 13.8212C-0.089464 13.4606 -0.0727225 12.8926 0.261454 12.5501C0.420188 12.3968 0.620747 12.3066 0.837962 12.3066H11.164C11.6235 12.3066 11.9995 12.7123 11.9995 13.2081C11.9995 13.4425 11.9159 13.6589 11.7739 13.8302L6.60243 19.7168Z" fill="#7D7D7D"/>
                        <path :class="{active: orderByElement == header.key && orderByDirection == 'asc'}" d="M5.39757 0.281221C5.71504 -0.0793729 6.2413 -0.0974378 6.57547 0.245127C6.58383 0.254142 6.60057 0.272206 6.60892 0.281221L11.772 6.17686C12.0895 6.53746 12.0727 7.10542 11.7385 7.44799C11.5798 7.60124 11.3793 7.69141 11.162 7.69141L0.835991 7.69141C0.376498 7.69141 0.000549251 7.28574 0.000549293 6.78992C0.000549313 6.55553 0.0841098 6.33912 0.226135 6.16784L5.39757 0.281221Z" fill="#7D7D7D"/>
                    </svg>

                </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="group in searchingGroups ? searchGroups : groups" 
            :id="`group-tr-${group.id}`"
            :ref="`group-tr-${group.id}`"
            :key="group.id"
            :class="[`bc-${group.color_id}`, (newsestGroup == group.id ? 'newest--group' : '', (group.enabled == 1 ? '' : 'disabled'))]"
          >
            <td 
                v-for="(header,index) in headers" 
                :key="index"
                :class="header.class"
                @click="header.key == 'name' ? editCell(group) : ''"
            >
                <div class="actions-container" v-if="header.key == 'actions'">
                    <!-- <VTooltip theme="users"  :triggers="['hover']" :placement="'top'">
                        <svg @click="toggleGroup(group.id)" :class="{disabled: !group.enabled}" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="15" fill="#E2E2E2"/>
                            <path d="M15.0001 7.84766C19.1439 7.84766 22.5 11.1259 22.5 15.1736C22.5 19.2213 19.1439 22.4995 15.0001 22.4995C10.8562 22.4995 7.5 19.2213 7.5 15.1736C7.51079 11.1364 10.867 7.8582 15.0001 7.84766ZM15.0001 20.9605C18.2698 20.9605 20.9245 18.3675 20.9245 15.1736C20.9245 11.9797 18.2698 9.38662 15.0001 9.38662C11.7303 9.38662 9.07563 11.9797 9.07563 15.1736C9.08642 18.3675 11.7303 20.95 15.0001 20.9605Z" fill="#4D4D4D"/>
                            <path d="M15.0006 13.7099C14.569 13.7099 14.2129 13.362 14.2129 12.9404V6.39446C14.2129 5.97283 14.569 5.625 15.0006 5.625C15.4323 5.625 15.7884 5.97283 15.7884 6.39446V12.9404C15.7884 13.362 15.4431 13.7099 15.0006 13.7099Z" fill="#4D4D4D"/>
                        </svg>

                    <template #popper>
                        {{toLocal('groups.desc')}}
                    </template>
                    </VTooltip> -->
                    <svg @click="toggleGroup(group.id)" :class="{disabled: !group.enabled}" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" rx="15" fill="#E2E2E2"/>
                        <path d="M15.0001 7.84766C19.1439 7.84766 22.5 11.1259 22.5 15.1736C22.5 19.2213 19.1439 22.4995 15.0001 22.4995C10.8562 22.4995 7.5 19.2213 7.5 15.1736C7.51079 11.1364 10.867 7.8582 15.0001 7.84766ZM15.0001 20.9605C18.2698 20.9605 20.9245 18.3675 20.9245 15.1736C20.9245 11.9797 18.2698 9.38662 15.0001 9.38662C11.7303 9.38662 9.07563 11.9797 9.07563 15.1736C9.08642 18.3675 11.7303 20.95 15.0001 20.9605Z" fill="#4D4D4D"/>
                        <path d="M15.0006 13.7099C14.569 13.7099 14.2129 13.362 14.2129 12.9404V6.39446C14.2129 5.97283 14.569 5.625 15.0006 5.625C15.4323 5.625 15.7884 5.97283 15.7884 6.39446V12.9404C15.7884 13.362 15.4431 13.7099 15.0006 13.7099Z" fill="#4D4D4D"/>
                    </svg>
                    <svg @click="deleteGroup(group.id)" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" rx="15" fill="#E2E2E2"/>
                        <path d="M17.0632 24.375H12.8318C10.6249 24.375 8.83144 22.6095 8.83144 20.4371V9.32949H21.0636V20.4311C21.0636 22.6035 19.2701 24.369 17.0632 24.369V24.375ZM9.74338 20.4371C9.74338 22.1128 11.1295 23.4773 12.8318 23.4773H17.0632C18.7655 23.4773 20.1517 22.1128 20.1517 20.4371V10.2272H9.74338V20.4371Z" fill="#D45454"/>
                        <path d="M20.9359 10.2392C20.8873 10.2392 20.8326 10.2392 20.784 10.2332H9.07462C8.65513 10.2332 8.25995 10.0716 7.96205 9.78438C7.66415 9.49113 7.5 9.10213 7.5 8.68918C7.5 7.83936 8.20523 7.14513 9.06854 7.14513H20.8265C20.8691 7.14513 20.9359 7.14513 21.0089 7.14513H21.0758C21.9452 7.23491 22.5774 7.98898 22.4923 8.8388C22.4498 9.25175 22.2491 9.6228 21.9269 9.88612C21.6412 10.1195 21.2946 10.2392 20.9299 10.2392H20.9359ZM9.06854 8.04284C8.70376 8.04284 8.41194 8.3301 8.41194 8.68918C8.41194 8.86274 8.47882 9.02433 8.60649 9.15001C8.73416 9.27568 8.89831 9.34152 9.07462 9.34152H20.8326C21.0575 9.35947 21.2217 9.30561 21.3615 9.19788C21.4953 9.08417 21.5804 8.92857 21.5986 8.761C21.6351 8.40192 21.3676 8.08473 21.0028 8.04882C20.9238 8.04882 20.8934 8.04882 20.863 8.04882H9.07462L9.06854 8.04284Z" fill="#D45454"/>
                        <path d="M17.6955 8.03683H12.1935L12.078 7.75555C12.005 7.57002 11.9625 7.37253 11.9625 7.17503C11.9625 6.32521 12.6677 5.63098 13.531 5.625H16.3459C17.2152 5.625 17.9205 6.31922 17.9205 7.17503C17.9205 7.37253 17.884 7.57002 17.811 7.75555L17.6955 8.03683ZM12.8805 7.13913H17.0085C16.9903 6.798 16.6985 6.5227 16.3519 6.5227H13.5371C13.1905 6.5227 12.8987 6.798 12.8805 7.13913Z" fill="#D45454"/>
                        <path d="M12.1995 21.3707C11.9503 21.3707 11.7436 21.1673 11.7436 20.9219V12.0825C11.7436 11.8371 11.9503 11.6337 12.1995 11.6337C12.4488 11.6337 12.6555 11.8371 12.6555 12.0825V20.9219C12.6555 21.1673 12.4488 21.3707 12.1995 21.3707Z" fill="#D45454"/>
                        <path d="M14.9475 21.3707C14.6982 21.3707 14.4915 21.1673 14.4915 20.9219V12.0825C14.4915 11.8371 14.6982 11.6337 14.9475 11.6337C15.1968 11.6337 15.4035 11.8371 15.4035 12.0825V20.9219C15.4035 21.1673 15.1968 21.3707 14.9475 21.3707Z" fill="#D45454"/>
                        <path d="M17.6955 21.3707C17.4462 21.3707 17.2395 21.1673 17.2395 20.9219V12.0825C17.2395 11.8371 17.4462 11.6337 17.6955 11.6337C17.9448 11.6337 18.1515 11.8371 18.1515 12.0825V20.9219C18.1515 21.1673 17.9448 21.3707 17.6955 21.3707Z" fill="#D45454"/>
                    </svg>
                    <!-- <VTooltip theme="users"  :triggers="['hover']" :placement="'top'">
                    <svg @click="deleteGroup(group.id)" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" rx="15" fill="#E2E2E2"/>
                        <path d="M17.0632 24.375H12.8318C10.6249 24.375 8.83144 22.6095 8.83144 20.4371V9.32949H21.0636V20.4311C21.0636 22.6035 19.2701 24.369 17.0632 24.369V24.375ZM9.74338 20.4371C9.74338 22.1128 11.1295 23.4773 12.8318 23.4773H17.0632C18.7655 23.4773 20.1517 22.1128 20.1517 20.4371V10.2272H9.74338V20.4371Z" fill="#D45454"/>
                        <path d="M20.9359 10.2392C20.8873 10.2392 20.8326 10.2392 20.784 10.2332H9.07462C8.65513 10.2332 8.25995 10.0716 7.96205 9.78438C7.66415 9.49113 7.5 9.10213 7.5 8.68918C7.5 7.83936 8.20523 7.14513 9.06854 7.14513H20.8265C20.8691 7.14513 20.9359 7.14513 21.0089 7.14513H21.0758C21.9452 7.23491 22.5774 7.98898 22.4923 8.8388C22.4498 9.25175 22.2491 9.6228 21.9269 9.88612C21.6412 10.1195 21.2946 10.2392 20.9299 10.2392H20.9359ZM9.06854 8.04284C8.70376 8.04284 8.41194 8.3301 8.41194 8.68918C8.41194 8.86274 8.47882 9.02433 8.60649 9.15001C8.73416 9.27568 8.89831 9.34152 9.07462 9.34152H20.8326C21.0575 9.35947 21.2217 9.30561 21.3615 9.19788C21.4953 9.08417 21.5804 8.92857 21.5986 8.761C21.6351 8.40192 21.3676 8.08473 21.0028 8.04882C20.9238 8.04882 20.8934 8.04882 20.863 8.04882H9.07462L9.06854 8.04284Z" fill="#D45454"/>
                        <path d="M17.6955 8.03683H12.1935L12.078 7.75555C12.005 7.57002 11.9625 7.37253 11.9625 7.17503C11.9625 6.32521 12.6677 5.63098 13.531 5.625H16.3459C17.2152 5.625 17.9205 6.31922 17.9205 7.17503C17.9205 7.37253 17.884 7.57002 17.811 7.75555L17.6955 8.03683ZM12.8805 7.13913H17.0085C16.9903 6.798 16.6985 6.5227 16.3519 6.5227H13.5371C13.1905 6.5227 12.8987 6.798 12.8805 7.13913Z" fill="#D45454"/>
                        <path d="M12.1995 21.3707C11.9503 21.3707 11.7436 21.1673 11.7436 20.9219V12.0825C11.7436 11.8371 11.9503 11.6337 12.1995 11.6337C12.4488 11.6337 12.6555 11.8371 12.6555 12.0825V20.9219C12.6555 21.1673 12.4488 21.3707 12.1995 21.3707Z" fill="#D45454"/>
                        <path d="M14.9475 21.3707C14.6982 21.3707 14.4915 21.1673 14.4915 20.9219V12.0825C14.4915 11.8371 14.6982 11.6337 14.9475 11.6337C15.1968 11.6337 15.4035 11.8371 15.4035 12.0825V20.9219C15.4035 21.1673 15.1968 21.3707 14.9475 21.3707Z" fill="#D45454"/>
                        <path d="M17.6955 21.3707C17.4462 21.3707 17.2395 21.1673 17.2395 20.9219V12.0825C17.2395 11.8371 17.4462 11.6337 17.6955 11.6337C17.9448 11.6337 18.1515 11.8371 18.1515 12.0825V20.9219C18.1515 21.1673 17.9448 21.3707 17.6955 21.3707Z" fill="#D45454"/>
                    </svg>

                    <template #popper  >
                    {{toLocal('groups.trainings_desc')}}
                    </template>
                    </VTooltip> -->
                </div>
                <div v-else-if="header.key == 'name'" class="border-left">
                    <span v-if="editingGroupID !== group.id">
                        {{ group.name }}
                    </span>
                    <div v-if="editingGroupID === group.id" :class="[`bc-${group.color_id}`]">
                        <input
                        ref="editingGroupName"
                        v-click-outside-2="disableCellEditing"
                        type="text" 
                        v-model="group.name"
                        >
                    </div>
                </div>
                <div v-else-if="header.percent" class="border-left">
                    {{ group[header.key].toFixed(1) }}%
                </div>
                <div v-else-if="header.key == 'supervisor'" class="border-left">
                     <CustomDropdown
                        v-if="group.users_formatted && typeof group.users_formatted === 'object'"
                        :list="Object.values(group.users_formatted)"
                        key-label="id"
                        value-label="fullName"
                        :compare-list="group.supervisor_id ? [{id:group.supervisor_id}] : []"
                        :options="{
                            closeOnClick: true,
                            checkbox: false,
                            customDDWidth: '100%',
                            customMaxHeight: '300px',
                            initial: group.supervisor_id == null ? 'groups.group_supervisor' : group.users_formatted[group.supervisor_id] ? group.users_formatted[group.supervisor_id].fullName : '',
                            containerClass: 'groups-table-dropdown',
                            searchBar: true,
                            searchLocally: true,
                            showCreateNewBtn:false
                        }"
                        @search="searchSupervisor($event, group)"
                        @elClicked="saveSupervisor($event, group)"
                    >
                        <template v-slot:arrow >
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.5L6 6.5L11 1.5" stroke="#333333" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </template>
                    </CustomDropdown>
                </div>
                <div v-else-if="header.key == 'edit'" class="edit-container border-left">
                    <svg @click="openedGroup=group" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" rx="15" fill="#E2E2E2"/>
                        <path d="M25.5 15C25.5 20.799 20.7989 25.5 14.9999 25.5C9.20094 25.5 4.5 20.799 4.5 15C4.5 9.20099 9.20094 4.5 14.9999 4.5C20.7989 4.5 25.5 9.201 25.5 15Z" stroke="#4D4D4D"/>
                        <circle cx="19.5" cy="15" r="1.5" transform="rotate(-180 19.5 15)" fill="#4D4D4D"/>
                        <circle cx="15" cy="15" r="1.5" transform="rotate(-180 15 15)" fill="#4D4D4D"/>
                        <circle cx="10.5" cy="15" r="1.5" transform="rotate(-180 10.5 15)" fill="#4D4D4D"/>
                    </svg>
                </div>
                <div v-else class="border-left">
                    {{ group[header.key] }}
                </div>
            </td>
          </tr>
        </tbody>
      </table>
      <portal to="group-edit-modal" >
        <GroupEditModal v-if="openedGroup!==null" :includable_columns="includable_columns" :user_data="additional_user_data" :group="openedGroup" @close="openedGroup=null"/>
      </portal>
    </div>
</template>

<script>
import axios from "axios";
import VueScrollTo from "vue-scrollto";
import CustomDropdown from "@/components/layout/elements/CustomDropdown.vue";
import GroupEditModal from "@/components/layout/groups/GroupEditModal.vue";

import GroupAddPlusSvg from "@/assets/images/icons/group-add-plus.svg?inline";
import GroupSearchSvg from "@/assets/images/icons/groups-search.svg?inline";
import GroupOpenUsersSvg from "@/assets/images/icons/groups-open-users.svg?inline";
import GroupOpenTrainingsSvg from "@/assets/images/icons/groups-open-trainings.svg?inline";
import GroupToggleGroupOffSvg from "@/assets/images/icons/groups-toggle-off.svg?inline";
import GroupToggleGroupOnSvg from "@/assets/images/icons/groups-toggle-on.svg?inline";
import GroupDeleteSvg from "@/assets/images/icons/groups-delete.svg?inline";
export default {
    components:{
        CustomDropdown,
        GroupEditModal,
        //SVG COMPONENTS
        GroupAddPlusSvg,
        GroupSearchSvg,
        GroupOpenUsersSvg,
        GroupOpenTrainingsSvg,
        GroupToggleGroupOffSvg,
        GroupToggleGroupOnSvg,
        GroupDeleteSvg
    },
    props:{
        searchInput:{
            type: String,
        }
    },
    activated(){
        this.loadGroups(null);
        this.loadUsers();
        let vm = this;
        this.$root.$on('group_user_count_modified', (id, count) => {
            let index = vm.groups.findIndex(group => group.id == id);
            vm.$set(vm.groups[index], 'user_count', count);

        })
    },
    deactivated() {
        setTimeout(() => {
            this.addingGroup = false;     
            this.searchingGroups = false;
            this.lastSearch = "-"; 
        }, 500);
        this.openedGroup = null;
    },
    watch:{
        searchInput(newVal){
            this.searchForGroups(newVal);
        },
        creatingGroup(){
            this.$emit('creatingGroupStatus', this.creatingGroup)
        }
    },
    data(){
        return{
            groups: [],
            newsestGroup: -1,
            searchingGroups: false,
            searchInverval: null,
            searchGroups: [],
            lastSearch: "-",
            loadingGroups: false,
            orderByElement: "",
            orderByDirection: "",
            selectedGroup: {},
            groupTrainingsSideOpen: false,
            editingGroupID: -1,
            usersCopy: [],
            users: [],
            headers:[
                {title: '', key: 'actions', class:'centered actions mw-100'},
                {title: 'generic.group_name', key: 'name', sortable: true, class:'editable-cell mw-35 group-name', borderLeft:true},
                {title: 'generic.user_count', key: 'user_count', sortable: true, class:'centered border-left mw-10', borderLeft:true},
                {title: 'group_side.assigned', key: 'training_count', sortable: true, class:'centered border-left mw-10', borderLeft:true},
                {title: 'trainings.started_trainings_percent', key: 'started_percent', sortable: true, class:'centered border-left mw-10', percent:true, borderLeft:true},
                {title: 'trainings.ended_trainings', key: 'ended_percent', sortable: true, class:'centered border-left mw-10', percent:true, borderLeft:true},
                {title: 'groups.group_supervisor', key: 'supervisor', sortable: true, class:'centered border-left mw-15', borderLeft:true},
                {title: '', key: 'edit', sortable: false, class:'centered', borderLeft:true},
            ],
            openedGroup: null,
            additional_user_data: [],
            includable_columns: [],
            creatingGroup:false
        }
    },
    methods:{
        offset: (el) => {
            var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
            },
            updateGroups(group_id, user_count){
            var group_array_index = this.groups.findIndex((el) => el.id == group_id);
            if(group_array_index != -1)
            this.$set(this.groups[group_array_index], 'user_count', user_count);
        },
        loadGroups(showGroup) {
            this.loadingGroups = true;
            axios.get( 
                `${this.$store.state.api_link}/group/groups`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.groups = response.data.data;
                this.loadingGroups = false;
                if(showGroup == null)
                return;
                this.newsestGroup = showGroup;
                setTimeout(() => {
                VueScrollTo.scrollTo(`#group-tr-${showGroup}`, 2000, {offset: -window.innerHeight*1.4});
                this.$store.commit("openSide", {"side": "group-side", "sideInfo": {"id": showGroup}});
                },10);
            }).catch((error) => {
                console.log(error)
            });
        },
        saveSupervisor(event, group) {
            this.$set(group, 'supervisor_id',event);
            axios.post( 
                `${this.$store.state.api_link}/group/${group.id}/save/supervisor`,
                {
                    user_id: event
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {

            }).catch((error) => {
                console.log(error)
            });
        },
        loadUsers(){
             axios.get( 
                `${this.$store.state.api_link}/user/users-paginate/50/0`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.users = response.data.users;
                for(let i = 0; i < this.users.length; i++){
                    this.users[i].fullName = this.users[i].name + " " + this.users[i].last_name;
                }
                for(let j = 0; j < this.groups.length; j++){
                    let users_formatted = this.groups[j].users;
                    let users_obj = {};
                    for(let i = 0; i < users_formatted.length; i++){
                        users_obj[users_formatted[i]] = this.users.find(el => el.id == users_formatted[i]);
                    }
                      
                    this.$set(this.groups[j], 'users_formatted', users_obj);
                }
                this.usersCopy = this._.cloneDeep(this.users)
                this.getAdditionalUserData()
            }).catch((error) => {
            });
        },
        getAdditionalUserData(){
            axios.get( 
                `${this.$store.state.api_link}/user/additional-data`,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.additional_user_data = response.data.users
                this.includable_columns = response.data.includable_columns
            }).catch((error) => {
            });
        },
        openGroup(id) {
            this.$store.commit("openSide", {"side": "group-side", "sideInfo": {"id": id}});
        },

        closeAddingGroups() {
            this.addingGroup = false;
        },
        toggleGroup(group) {
            axios.post(
                `${this.$store.state.api_link}/group/toggle`,
                {group: group},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if(response.data.status == "success"){
                this.groups[this.groups.findIndex(el => el.id === group)].enabled = response.data.group_status;
                }else{
                console.log("Error while trying to toggle group");
                }
            })
            .catch(() => {
                console.log("Server error while trying to toggle group");
            });
        },
        deleteGroup(group_id) {
            if(!confirm(`\n\n🗑️ ${this.toLocal('groups.are_you_sure')}\n\n`))
                return;
            axios.post(
                `${this.$store.state.api_link}/group/remove`,
                {group: group_id},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                if(response.data.status == "success"){
                let index = this.groups.findIndex(el => el.id == group_id);
                if(index != -1)
                    this.groups.splice(index, 1);
                let search_index = this.searchGroups.findIndex(el => el.id == group_id);
                if(search_index != -1)
                    this.searchGroups.splice(search_index, 1);
                }else{
                console.log("Error while trying to remove group");
                }
            })
            .catch(() => {
                console.log("Server error while trying to remove group");
            });
        },
        setOrderBy(element) {
            if(element == this.orderByElement){
                this.orderByDirection = this.orderByDirection == "desc" ? "asc" : "desc";
            }else{
                this.orderByElement = element;
                this.orderByDirection = "asc";
            }
            if(isNaN(this.groups[0][element])){
                if(this.orderByDirection == "desc"){
                this.groups.sort((a,b) => b[element].localeCompare(a[element])); 
                }else{
                this.groups.sort((a,b) => a[element].localeCompare(b[element])); 
                }
            }else{
                if(this.orderByDirection == "desc"){
                this.groups.sort((a,b) => (a[element] > b[element]) ? -1 : ((b[element] > a[element]) ? 1 : 0)); 
                }else{
                this.groups.sort((a,b) => (a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)); 
                }
            }
        },
        disableCellEditing(e){
            this.saveGroupName(this.editingGroupID);
            this.editingGroupID = -1;
        },
        saveGroupName(id){
            let group = this.groups.find(el => el.id == id);
            if(group != null){
                axios.put(
                `${this.$store.state.api_link}/groups/${id}`,
                {name: group.name},
                this.$store.getters.axiosConfig()
                ).then((response) => {
                })
                .catch(() => {
                console.log("Server error while trying to toggle group");
                });
            }
        },
        editCell(group){
            let previousGroupId = this.editingGroupID;
            this.editingGroupID=group.id;
            if(previousGroupId == -1){
                this.$nextTick(()=>{
                let input = this.$refs.editingGroupName;
                if(Array.isArray(input)) input = this.$refs.editingGroupName[0];
                if(input)
                    input.select();
                })
            }
        },
        searchSupervisor(input, group) {
            if(input == null){
                let users = Object.values(group.users_formatted)
                this.users = this._.cloneDeep(this.usersCopy);
            }
            else {
                let users = Object.values(group.users_formatted)
                
                this.users = this.users.filter((t) => {
                    return (t.name.toLowerCase()+' '+t.last_name.toLowerCase()).trim().includes(input.toLowerCase());
                });
            }
        },
        searchForGroups(input){
            if(input == null || input == ""){
                this.searchGroups = this._.cloneDeep(this.groups);
                this.searchingGroups = false;
            }
            else{
                this.searchingGroups = true;
                this.searchGroups = this.groups.filter(group => {
                    let show_group = false;
                    show_group = group.name.trim().toLowerCase().includes(input.toLowerCase());
                    if(!show_group){
                        if(group.supervisor_id && group.users_formatted[group.supervisor_id]){
                            let supervisor = group.users_formatted[group.supervisor_id];
                            if(supervisor){
                                show_group = (supervisor.name.trim()+' '+supervisor.last_name.trim()).toLowerCase().includes(input.toLowerCase());
                            }
                        }
                    }
                    return show_group;
                });
            }
        },
        createNewGroup(){
            this.creatingGroup=true;
            axios.post( 
                `${this.$store.state.api_link}/group/add`,
                {
                    group: this.toLocal('groups.new_group'),
                },
                this.$store.getters.axiosConfig()
                ).then((response) => {
                     if(response.data.status == "success"){
                        let new_group = response.data.added_group;
                        this.$set(new_group,'users_formattedCopy', []);
                        this.$set(new_group,'newly_added', true);
                        this.$set(new_group,'name_set', false);
                        this.groups.unshift(new_group);
                        this.searchGroups.unshift(new_group);
                        this.openedGroup=new_group;
                    }else{
                        console.log("Error while trying to add new group");
                    }
                    this.creatingGroup=false;
                })
                .catch((err)=>{
                    this.creatingGroup=false;
                });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/views/_groupsViewScoped.scss";
.table-groups {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  thead {
    th {
      font-weight: normal;
      font-size: 16px;
      text-align: left;
        span{
            padding: 0 8px;
        }
      &.large {
        font-size: 30px;
        font-weight: 500;
      }

      &:first-child {
        -webkit-border-top-left-radius: 35px;
        -webkit-border-bottom-left-radius: 35px;
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
      }

      &:last-child {
        -webkit-border-top-right-radius: 35px;
        -webkit-border-bottom-right-radius: 35px;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
      }
      &.border-left {
        border-left: 1px solid #707070;
        white-space: nowrap;
      }
      .header-cell{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      svg{
        margin: 0 8px;
        path{
            &.active{
                fill: #333333;
            }
        }
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        margin-top: 10px;
      }
    }

    td {
        padding: 6px 0px;
        max-width: 25%;
        min-width: 75px;
        min-height: 48px;
        background-color: #fff;
        &.mw-10{
            min-width: 10%;
        }
        &.mw-15{
            min-width: 15%;
        }
        &.mw-20{
            min-width: 20%;
        }
        &.mw-35{
            min-width: 35%;
            max-width: 35%;
        }
        &.mw-100{
            min-width: 100%;
        }
        &.group-name{
            span{
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 300px;
            }
        }
      img {
        vertical-align: middle;
      }

      &:first-child {
        -webkit-border-top-left-radius: 35px;
        -webkit-border-bottom-left-radius: 35px;
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
        padding-left: 35px;
      }

      &:last-child {
        -webkit-border-top-right-radius: 35px;
        -webkit-border-bottom-right-radius: 35px;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
      }

      &.centered {
        text-align: center;
        padding: 6px 0;
      }

      &.large {
        font-size: 1.35em;
      }

      &.actions {
        img {
          padding: 0 10px;
          cursor: pointer;
          vertical-align: middle;
        }
        svg {
          cursor: pointer;
          vertical-align: middle;
          overflow: hidden;

          &:hover {
            &.stroke path,
            &.stroke line,
            &.stroke ellipse {
              stroke: #6d66a2;
              &.hover-fill {
                fill: #6d66a2;
              }
            }
          }
        }
      }

      &.padded {
        padding: 15px 20px;
      }

      .border-left {
        border-left: 1px solid #707070;
        width: 100%;
        padding: 0 8px;
      }
    }
  }
}
.editable-cell{
  input{
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 20px;
    background: none;
    &:focus{
      outline: none;
    }
  }
  div{
    border-width: 2px;
  }
  span{
    display: flex;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
}
.table-container{
    display: flex;
    flex-direction: column;
}
.edit-container{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: 72px;
    gap: 8px;
}
.actions{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    max-width: 100px;
}
.actions-container{
    display: flex;
    align-items: center;
    padding: 0 8px;
    max-width: 83px;

    gap: 8px;
    svg{
        &:hover{
            rect{
                fill: #D45454;
            }
            path{
                fill: white;
            }
        }
        &.disabled{
            rect{
                fill: #D45454;
            }
            path{
                fill: white;
            }
        }
    }
}
.groups-table-dropdown{
    background: none;
    border-radius: 6px;
    border: 1px solid #B2B2B2;
    background: none;
    max-width: 12rem;
    &:not(.no-padding-no-bg){
        background: none;
        color: #333;
        .dropdown-initial{
            color: #333;
        }
    }
    .initial{
        svg{
        }
    }
}
</style>