<template>
  <div class="chat-container">
      <div class="selected-user-container" v-if="selectedChatItem && selectedChatItem.user && chatMode == 0">
          <div
            :class="['user', 'active', selectedChatItem.is_important ? 'important' : '']"
          >

            <div class="user--name">
              <img 
                src="../../../assets/images/icons/user.svg"
              >
              
              <span class="email">
                {{ selectedChatItem.user.email }}
              </span>
              <div class="groups">
                  <div class="group--parent--container">
            
                    <div
                    v-for="(group, groupIndex) in selectedChatItem.user.groups"
                    :key="groupIndex"
                    
                    :style="{'max-width': selectedChatItem.user.groups.length > 3 ? `${100 / selectedChatItem.user.groups.length}%` : '', 'margin': '0 0.3rem', 'margin-left': groupIndex != 0 ? `-${selectedChatItem.user.groups.length-1}%` : `${selectedChatItem.user.groups.length-1}%`}"
                    class="group--item" 
                    >

                    <VTooltip theme="users"  :triggers="['hover']" :placement="'top'">
                        <div  :class="['group', 'g'+(groupIndex + 1), 'c'+group.color_id]">
                        {{ group.name[0] }}
                        </div>
                            <template #popper>
                        {{group.name}}
                    </template>
                    </VTooltip>

                    
                    </div>
                </div>
              </div>
              <div class="important-status" @click="toggleImportant(selectedChatItem)">
                <svg xmlns="http://www.w3.org/2000/svg" width="18.99" height="25.202" viewBox="0 0 18.99 25.202">
                    <path id="Path_8308" data-name="Path 8308" d="M-15405.971,2894.552h17.99v23.781l-9.008-5.838-8.982,5.838Z" transform="translate(15406.471 -2894.052)" fill="#fff" stroke="#28345c" stroke-width="1"/>
                </svg>

              </div>

            </div>
          </div>
      </div>
       <div 
            v-if="chatLoading && chatMode == 0"
            
        >
            <h2><font-awesome-icon icon="spinner" /></h2>
          </div>
          <div v-if="!chatLoading && selectedChat == -1  && chatMode == 0">
            <h2>{{ toLocal('email.select_chat') }}</h2>
            <p>{{ toLocal('email.select_chat_description') }}</p>
          </div>
          <div 
            v-if="selectedChat != -1 && !chatLoading  && chatMode == 0"
            class="chat--messages--wrapper"
          >
            <div
              v-for="(message, messageIndex) in chatMessages"
              :key="message.id"
              ref="chatContainer"
            >
              <div 
                v-if="displayTimestamp(messageIndex)"
                class="timestamp"
              >
                {{ message.time ? message.time : '' }}
              </div>
              <div :class="['message message--' + message.location]">
                <div
                  v-if="displayAvatar(messageIndex)" 
                  class="avatar"
                >
                  {{ message.avatar }}
                </div>
                <!-- <div 
                  v-if="message.sending" 
                  class="message--loading"
                >
                  <font-awesome-icon icon="spinner" />
                </div> -->
                <div class="message--text">
                  {{ message.message }}
                </div>
              </div>
            </div>
          </div>
          <div 
            v-if="selectedChat != -1 && !chatLoading &&  chatMode == 0"
            class="chat--input--wrapper"
          >
            <input 
              ref="chatInput"
              type="text"
              class="chat--input"
              :placeholder="toLocal('chat.write_message')"
              @keyup.enter="enterChatMsg()"
            >
            <svg @click="enterChatMsg()" xmlns="http://www.w3.org/2000/svg" width="26.464" height="25.401" viewBox="0 0 26.464 25.401">
              <g id="Group_707" data-name="Group 707" transform="translate(0.251 0.27)">
                <path id="Path_783" data-name="Path 783" d="M437.333,426.667Z" transform="translate(-415.157 -405.031)" fill="#707070" stroke="#707070" stroke-width="0.5"/>
                <path id="Path_784" data-name="Path 784" d="M25.953.929c0-.021.005-.041.007-.062s0-.02,0-.031,0-.023,0-.034,0-.026,0-.039,0-.011,0-.017A.81.81,0,0,0,24.782.086l-.007,0L24.757.1,24.747.1.434,12.8a.812.812,0,0,0,.115,1.487l8.916,3.047v6.732a.811.811,0,0,0,.811.811h0a.816.816,0,0,0,.654-.33l3.942-5.365,7.042,2.407a.829.829,0,0,0,.262.043.813.813,0,0,0,.8-.691L25.952.936h0ZM21.325,3.722l-11.277,12.1L2.862,13.363ZM11.088,21.593v-3.7l2.175.743Zm10.428-1.854-9.785-3.345L23.964,3.273Z" transform="translate(0.001 0.003)" fill="#707070" stroke="#707070" stroke-width="0.5"/>
              </g>
            </svg>
        </div>
        <div v-else-if="chatMode == 1" class="chat-right-container">
           <div 
            class="email--select--wrapper"
          >
            {{ toLocal('email.template_sen_1') }}
              <select-input
                v-model="selectedReceiverType" 
                :options="static_recipient_types"
                :selectedReceiverType="selectedReceiverType"
                :selectedUsers="selectedUsers"
                width="8rem"
              />
              <select-input
                :key="selectedReceiverType"
                v-if="selectedReceiverType == toLocal('mail.user') "
                v-model="selectedUsers"
                label="fullName"
                multichoice
                :selectedReceiverType="selectedReceiverType"
                :canSelectAll="true"
                :options="allUsers" 
                :selectAllLabel="toLocal('chat.select_all_users')"
                
              />
              <select-input
                :key="selectedReceiverType"
                v-if="selectedReceiverType == toLocal('chat.users_from') "
                v-model="selectedGroups"
                label="name"
                width="10rem"
                multichoice
                :canSelectAll="true"
                :selectAllLabel="toLocal('tests.all_groups')"
                :options="allGroups" 
              />
              {{getGroupLabel()}}

           </div>
           <div class="selected--users">
            <div class="btn--multi"  @click="sideOpen = true">
              <div>{{toLocal('chat.view_recipients')}}</div>
            </div>
          </div>
          <div class="input-container">
             <textarea 
              type="text"
              class="chat--input"
              v-model="groupChatInput"
              :placeholder="toLocal('chat.write_message')"
            >
            </textarea>
            <div class="success-msg" v-if="messageSent">
              {{userCount > 0 ? toLocal('chat.sent_successfully')+ userCount+' '+ getUserTextEnd : toLocal('chat.unable_to_send')+(adminUsers > 0 ? toLocal('chat.cant_send_to_admins') : '')}} {{adminUsers != 0 && userCount > 0 ? ', '+adminUsers+toLocal('chat.which_admins') : ''}}
            </div>
            <div class="send-btn" :class="{'loading': sendingMessage}" @click="sendEmailPrompt()">
              <svg  xmlns="http://www.w3.org/2000/svg" width="26.464" height="25.401" viewBox="0 0 26.464 25.401">
                <g id="Group_707" data-name="Group 707" transform="translate(0.251 0.27)">
                  <path id="Path_783" data-name="Path 783" d="M437.333,426.667Z" transform="translate(-415.157 -405.031)" fill="#707070" stroke="#707070" stroke-width="0.5"/>
                  <path id="Path_784" data-name="Path 784" d="M25.953.929c0-.021.005-.041.007-.062s0-.02,0-.031,0-.023,0-.034,0-.026,0-.039,0-.011,0-.017A.81.81,0,0,0,24.782.086l-.007,0L24.757.1,24.747.1.434,12.8a.812.812,0,0,0,.115,1.487l8.916,3.047v6.732a.811.811,0,0,0,.811.811h0a.816.816,0,0,0,.654-.33l3.942-5.365,7.042,2.407a.829.829,0,0,0,.262.043.813.813,0,0,0,.8-.691L25.952.936h0ZM21.325,3.722l-11.277,12.1L2.862,13.363ZM11.088,21.593v-3.7l2.175.743Zm10.428-1.854-9.785-3.345L23.964,3.273Z" transform="translate(0.001 0.003)" fill="#707070" stroke="#707070" stroke-width="0.5"/>
                </g>
              </svg>
            </div>
          </div>
      </div>
      <UserDrawer :users="selectedReceiverType == toLocal('chat.users_from') ? selectedGroups : selectedUsers" :usersType="selectedReceiverType == toLocal('chat.users_from') ? 'groups' : 'users'"  :sideOpen="sideOpen" @closeSide="sideOpen = false"/>
      <GlobalModal
        v-if="emailPromptActive"
        :modalInfo="{
          type: 'confirmation',
          text: 'modal.send_emails',
          confirmBtnText: 'modal.yes_send',
        }"
        @onConfirm="sendGroupChat"
        @onClose="emailPromptActive = false"
      />
  </div>
</template>

<script>
import axios from 'axios';
import SelectInput from '@/components/layout/emails/SelectInput.vue'
import UserDrawer from '@/components/layout/emails/UserDrawer.vue'
import moment from "moment";
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";

export default {
  components:{
    SelectInput,
    UserDrawer,
    GlobalModal
  },
    props:{
        chatMessages:{
            type: Array,
            default: function () {
                return [];
            }
        },
        selectedChat:{
            type: Number,
            default: -1
        },
        selectedChatItem:{
            type: Object,
            default: function () {
                return {};
            }
        },
        chatMode:{
            type: Number,
            default: 0
        },
    },
    data(){
        return{
            chatLoading: false,
            static_recipient_types: [
                this.toLocal('chat.users_from'),
                this.toLocal('mail.user')
            ],
            allGroups: [],
            allUsers: [],
            selectedUsers: [],
            selectedGroups: [],
            selectedReceiverType: null,
            sideOpen: false,
            groupChatInput: '',
            messageSent: false,
            userCount: 0,
            adminUsers: 0,
            sendingMessage: false,
            emailPromptActive:false,

        }
    },
    computed:{

      getUserTextEnd(){
        if(this.userCount == 1){
          return this.toLocal('chat.user_to_who')
        }else if(this.userCount > 1 && this.userCount < 10){
          return this.toLocal('chat.users_to_who')
        }else{
          return this.toLocal('chat.users_who')
        }
      }
    },
    mounted(){
      this.loadAllUsers();
      this.loadAllGroups();
    },
    beforeUnmount(){
   
    },
    watch: {
      selectedReceiverType() {
          this.selectedGroups = []
          this.selectedUsers = []
      }
    },
    methods:{
         enterChatMsg() {
            let element = this.$refs['chatInput'];
            let msg = element.value;
            if(msg.length == 0)
                return;
            element.value = "";
            let message = {
                avatar: this.$store.state.user_email.substring(0,1).toUpperCase(),
                location: "right",
                time: moment(new Date()).format("YYYY-MM-DD | HH:mm"),
                message: msg,
                sending: true,
            };
            this.chatMessages.push(message);
            axios.post(
                `${this.$store.state.api_link}/chat/send/user`,
                {user: this.selectedChatItem.user.id, message: msg},
                this.$store.getters.axiosConfig()
            ).then((response) => {
                // this.chatMessages = response.data.data;
            })
            .catch(() => {
            });
            setTimeout(() => {
                let wrapper = this.$el.querySelector(".chat--messages--wrapper");
                wrapper.scrollTop = wrapper.scrollHeight;
            },10);
      },
        sendEmailPrompt() {
          this.emailPromptActive = true;
        },
        sendGroupChat(){
          let JSON = {};
          if(this.sendingMessage || (this.selectedUsers.length == 0 && this.selectedGroups.length == 0)) return;
          this.sendingMessage=true;
          this.userCount = 0;
          this.emailPromptActive = false;
          if(this.selectedReceiverType == this.toLocal('mail.user')){
            JSON = {
              users: this.selectedUsers.map(el=>el.id),
              message: this.groupChatInput
            }
          }
          else{
             JSON = {
              groups: this.selectedGroups.map(el=>el.id),
              message: this.groupChatInput
            }
          }
            axios.post(
                `${this.$store.state.api_link}/chat/send/multi`,
                JSON,
                this.$store.getters.axiosConfig()
            ).then((response) => {
                this.groupChatInput = '';
                this.userCount = response.data.user_count;
                this.adminUsers = response.data.admin_users.length;
                this.messageSent = true;
                this.sendingMessage=false;

                setTimeout(() => {
                    this.messageSent = false;
                },3000);
            })
            .catch(() => {
                this.sendingMessage=false;

            });
        
        },
        displayTimestamp(msgIndex) {
            if(msgIndex == 0)
                return true;
            let currentTime = '';
            let currentDate = '';
            let currentHour = '';
            let currentMin = '';
            let lastTime = '';
            let lastDate = '';
            let lastHour = '';
              if(this.chatMessages[msgIndex].time == undefined && this.chatMessages[msgIndex].created_at != undefined){
                currentTime = this.chatMessages[msgIndex].created_at.split(" ");
                currentDate = currentTime[0];
                currentHour = currentTime[1].split(":")[0];
                currentMin = currentTime[1].split(":")[1];
                lastTime = this.chatMessages[msgIndex - 1].created_at ? this.chatMessages[msgIndex - 1].created_at.split(" ") : this.chatMessages[msgIndex - 1].time.split(" \| ");
                lastDate = lastTime[0];
                lastHour = lastTime[1].split(":")[0];
                this.chatMessages[msgIndex].time = currentDate + ' | ' + currentHour+':'+currentMin;
              }
              else if(this.chatMessages[msgIndex].time != undefined){
                currentTime = this.chatMessages[msgIndex].time.split(" \| ");
                currentDate = currentTime[0];
                currentHour = currentTime[1].split(":")[0];
                lastTime = this.chatMessages[msgIndex - 1].time ? this.chatMessages[msgIndex - 1].time.split(" \| ") : this.chatMessages[msgIndex - 1].created_at.split(" ");
                lastDate = lastTime[0];
                lastHour = lastTime[1].split(":")[0];
              }
            if(currentDate != lastDate || currentHour != lastHour)
                return true;
            return false;
        },
        displayAvatar(msgIndex) {
            if(msgIndex == 0)
                return true;
            if(this.chatMessages[msgIndex].location != this.chatMessages[msgIndex - 1].location)
                return true;
            if(this.chatMessages[msgIndex].avatar != this.chatMessages[msgIndex - 1].avatar)
                return true;
            let currentTime = this.chatMessages[msgIndex].time.split(" \| ");
            let currentDate = currentTime[0];
            let currentHour = currentTime[1].split(":")[0];
            let lastTime = this.chatMessages[msgIndex - 1].time.split(" \| ");
            let lastDate = lastTime[0];
            let lastHour = lastTime[1].split(":")[0];
            if(currentDate != lastDate || currentHour != lastHour)
                return true;
            return false;
        },
        toggleImportant(chat){
            this.$set(chat, 'is_important', !chat.is_important);
            axios.post( 
                `${this.$store.state.api_link}/chat/important`,
                {
                    chat: chat.id,
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
              
            }).catch((error) => {
                console.log(error)
            });
        },
        getGroupLabel(){
            if(this.selectedReceiverType != this.toLocal('chat.users_from')) return
            if(this.selectedGroups){
              if(this.selectedGroups.length == 1){
                return this.toLocal('chat.groups');
              }
              else{
                return this.toLocal('chat.groups_who');
              }
            }
            return '';
        },
        loadAllUsers() {
              axios.get( 
                  `${this.$store.state.api_link}/user/users`,
                  this.$store.getters.axiosConfig()
              ).then((response) => {
                this.allUsers = response.data.data;
                  this.allUsers = this.allUsers.filter(
                    (user) => user && user.email && user.email.length > 0
                );
                  this.allUsers = this.allUsers.map(el => {
                    return {...el, fullName: el.name + ' ' + (el.last_name ? el.last_name : '')}
                  })
                  this.allUsers.sort((a, b) => {
                    if(a.fullName && b.fullName){
                      if (a.fullName.localeCompare(b.fullName) < 0 ) return -1;
                      else return 1;
                    }
                    else if(a.fullName && !b.fullName){
                      return -1;
                    }
                    else return 1;
                  });
              }).catch((error) => {
                  console.log(error)
              });
          },
          loadAllGroups() {
              axios.get( 
                  `${this.$store.state.api_link}/group/groups`,
                  this.$store.getters.axiosConfig()
              ).then((response) => {
                this.allGroups = response.data.data;
                  this.allGroups = this.allGroups.filter(
                    (group) =>  group && group.name && group.name.length > 0
                  );
                  this.allGroups.sort((a, b) => {
                    if (a.name.localeCompare(b.name) < 0 ) return -1;
                    else return 1;
                  });
                  

              }).catch((error) => {
                  console.log(error)
              });
          },
      
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.chat-container{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.chat--messages--wrapper {
    height: calc(100vh - 240px);
    // margin: 0 -100px;
    overflow-y: auto;
  }
  .message {
    margin: 8px 100px;
    position: relative;
    .message--text {
      background-color: #fff;
      padding: 15px 25px;
      border-radius: 20px;
      display: inline-block;
    }
    .message--loading {
      position: absolute;
    }
    .avatar {
      position: absolute;
      margin-left: -70px;
      background-color: #fff;
      font-size: 30px;
      font-weight: 900;
      width: 58px;
      height: 58px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
 
    &.message--right {
      text-align: right;
      .avatar {
        right: 0;
        margin-left: 0px;
        margin-right: -70px;
      }
    }
  }
  .timestamp {
  font-size: 15px;
  border-bottom: 1px solid #707070;
  margin: 0 120px;
  padding: 6px 0;
}
  .chat--input--wrapper {
    padding: 10px 0 0 0;
    position: relative;
    .chat--input {
      width: 100%;
      border: none;
      background-color: #fff;
      padding: 15px 27px;
      font-family: var(--main-site-font);
      font-size: 20px;
      color: #707070;
      border-radius: 20px;
      outline: none;
    }
    svg {
      position: absolute;
      cursor: pointer;
      top: 27px;
      right: 25px;
      user-select: none;
      overflow: hidden;
      transition: transform .3s;
      &:hover{
        transform: scale(1.1) rotate(5deg);
      }
    }
  }
   .user {
    background-color: #fff;
    border-radius: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: .3s all;
    user-select: none;
    position: relative;
    height: 3rem;
    color: var(--primary-color);
    
    .user--name {
        padding: 0px 20px;
        white-space: nowrap;
        height: 100%;
        display: flex;
        align-items: center;
      
      img {
        margin-right: 25px;
        height: 25px;
        vertical-align: middle;
      }
      .email{
          display: flex;
          flex:1;
      }
    }

    &.important{
         border-bottom: 4px solid var(--secondary-color);
        .important-status{
            svg{
                path{
                    fill: var(--secondary-color);
                }
            }
        }
    }
    &.active { 
        border-bottom: 4px solid var(--primary-color);
        font-weight: 700;
    }
    &.unread{
        border-bottom: 4px solid #FF5858;
    }
    .unread--messages {
      background-color: #FF5858;
      width: 22px;
      height: 22px;
      top: -5px;
      left: -5px;
      border-radius: 100%;
      position: absolute;
    }
    .user--groups {
        float: right;
      width: 240px;
      height: 60px;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      overflow: hidden;
      position: relative;
      &:hover .group {
        @for $i from 2 through 15 {
          &.g#{$i} {
            width: 10% - ($i - 1) * 4;
          }
        }
      }
      .group {
          position: absolute;
        width: 100%;
        height: 100%;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s width;
        user-select: none;
        overflow: hidden;
        color: #464646;
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        @for $i from 0 through 14 {
          &.c#{$i} {
            background-color: hsl(($i * 40 % 360), (20 + 2.5 * $i) % 70 * 1%, var(--theme-lightness));
          }
          &.g#{$i} {
            width: 100% - ($i - 1) * 10;
          }
        }
        &:hover {
          @for $i from 0 through 14 {
            &.g#{$i} {
              width: 100% - ($i - 1) * 2;
            }
          }
        }
      }
    }
    .important-status{
        position: absolute;
        right: 1.5rem;
        top: 0;
        svg{
            
        }
       
    }
  }
  .groups {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 180px;
  max-width: 180px;
  padding: 0;
  padding-right: 10px;
  position: relative;
  height: 60px;
  // float: right;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  overflow: hidden;
    justify-content: center;
    margin-right: 2rem;
    max-width: calc(180px + 2rem);
    width: calc(180px + 2rem);
    

  .group--parent--container{
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 0 0 1rem;
    border-left: 1px solid;
  }
  &:hover .group {
    @for $i from 2 through 15 {
      &.g#{$i} {
        width: 10% - ($i - 1) * 4;
      }
    }
  }
.group--item {
  display: flex;
  justify-content: center;
  align-items: center;

  // position: relative;
  width: 100%;
  overflow: visible;
}
  .group {
    // position: relative;
    width: 100%;
    height: 100%;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s width;
    user-select: none;
    border-radius: 50%;
    min-width: 40px;
    height: 40px;
    max-width: 40px;
    font-size: clamp(24px, 2vh, 30px);

    // @for $i from 0 through 15 {
    //   &.c#{$i} {
    //     background-color: hsl((3 + ($i - 1) * 24), 100%, 76%);
    //   }
    //   &.g#{$i} {
    //     width: 100% - ($i - 1) * 10;
    //   }
    // }
     @for $i from 0 through 15 {
      &.c#{$i} {
        background-color: hsl(($i * 40 % 360), (20 + 2.5 * $i) % 70 * 1%, var(--theme-lightness));
      }
      &.g#{$i} {
        width: 100% - ($i - 1) * 10;
      }
    }

    &:hover {
      @for $i from 0 through 15 {
        &.g#{$i} {
          width: 100% - ($i - 1) * 2;
        }
      }
    }
  }
}
  .email--select--wrapper {
    // height: calc(100vh - 240px);

    margin-bottom: 30px;
    font-size: clamp(24px, 1.1vw, 30px);
    color: var(--primary-color);
    line-height: 55px;
    margin-bottom: 10px;
    span {
      color: var(--primary-color);
      border-bottom: 4px solid #FFD93E;
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin-left: 10px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 8px;
        background-image: url("../../../assets/images/icons/dropdown-arrow.svg");
        background-repeat: no-repeat;
        margin-left: -15px;
        margin-top: 28px;
      }
    }
  }
    .selected--users{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn--multi{
      display: flex;
      justify-content: center;
      color: var(--primary-color);
      background: white;
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 0.15rem 0.25rem;
      font-size: clamp(14px, 0.9vw, 16px);
      width: 137px;
      border: 1px solid transparent;
      min-height: 1.75rem;
      cursor:pointer;
      &:hover{
        border: 1px solid var(--primary-color);
        div:last-child{
          text-shadow: 1px 0 0 var(--primary-color);
        }
      }
      &:active{
        background: var(--secondary-color);
      }
      div{
        flex:2;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        &:first-child{
          // margin-right: 0.25rem;
        }
      }
      div:last-child{
        flex:3;
        // border-left: 1px solid var(--primary-color);
      }
    }
  }
  .input-container{
    background: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    position: relative;

    textarea{
      resize: none;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 1rem;
      padding: 1.5rem 1rem;
      font-size: clamp(16px, 1.2vw, 24px);
      &:focus-visible{
        outline: 2px solid var(--primary-color)
      }

    }

    .send-btn{
      position: absolute;
      right: 1rem;
      bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      border-radius: 50%;
      cursor: pointer;
      &:hover{
        background: var(--secondary-color);
      }
      &.loading{
        animation: rotate 1s ease-in-out infinite;
      }
     
    }

  }
  @keyframes rotate{
    0%{
      transform: rotate(-0deg);
    }
    100%{
      transform: rotate(-360deg);
    }
  }
  .chat-right-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  .success-msg{
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    border: 1px solid;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: white;

  }
</style>